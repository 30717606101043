import gql from 'graphql-tag';

export const limitBlockingFragment = gql`
  fragment LimitBlockingFragment on Limit {
    blockingStatus {
      configuredAt
      blocked
      initiatorType
      validFrom
    }
  }
`;

export const limitFragment = gql`
  fragment LimitFragment on PeriodLimit {
    value
    available
    currentPeriodEndTime
    pendingPeriodLimit {
      configuredAt
      validFrom
      value
    }
    pendingPeriodLimitRemoval {
      validFrom
    }
  }
`;
